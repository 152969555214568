import React, { useState, useEffect } from 'react';
import { Card, Stack, Select } from '@contentful/f36-components';
import { updateEntry } from '../utils/contentful';

const ListCard = ({ title, subtitle, selectedOption, options, entryId, sdk }: any) => {
  const cma = sdk.cma;
  const { userRoleTagMapping } = sdk.parameters.installation.applicationConfig;
  const [selectValue, setSelectValue] = useState(selectedOption);
  const [canEdit, setCanEdit] = useState(false);

  const handleChange = (event: any) => {
    setSelectValue(event.target.value);
    updateEntry(cma, entryId, event.target.value, options);
  };

  /*
    1) Activate the tag selection if the user is an admin.
    2) Filter all roles based on the selected tag. If the user has one of the filtered roles assgigned,
    then activate the tag selection.
  */
  useEffect(() => {
    (async () => {
      if (
        sdk.user.spaceMembership.admin ||
        sdk.user.spaceMembership.roles.find((spaceMember) => spaceMember.name === 'Supporter')
      ) {
        setCanEdit(true);
      } else {
        const filteredRoles = userRoleTagMapping.filter((tag) => tag.tagId === selectValue);
        const hasRole = filteredRoles.some((role) => {
          if (
            sdk.user.spaceMembership.roles.find((spaceMember) => spaceMember.name === role.roleName)
          ) {
            return true;
          }
        });
        if (hasRole) {
          setCanEdit(true);
        }
      }
    })();
  }, [sdk, userRoleTagMapping, selectValue]);

  return (
    <div>
      <Card as="div" style={{ alignItems: 'center', height: '45px' }} padding="none">
        <Stack
          style={{ paddingLeft: '0.75rem', paddingRight: '5px' }}
          justifyContent="space-between"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <strong>{title}</strong> ({subtitle ? subtitle : 'Untitled'})
          </div>
          <Select
            size="small"
            id="optionSelect-controlled"
            name="optionSelect-controlled"
            value={selectValue}
            onChange={handleChange}
            isDisabled={!canEdit}
          >
            <Select.Option value="">Select</Select.Option>
            {options.map((option: any, i: number) => (
              <Select.Option value={option} key={i.toString()}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Stack>
      </Card>
    </div>
  );
};

export default ListCard;
