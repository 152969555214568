import React from 'react';
import ListCard from './ListCard';

const ListItem = ({ treeData, governanceTags, contentTypes, sdk }: any) => {
  const defaultLocale = sdk.locales.default;

  const contentTypeName = contentTypes.find(
    (ct: any) => ct.sys.id === treeData.item?.sys?.contentType?.sys?.id
  )?.name;
  const displayField = contentTypes.find(
    (ct: any) => ct.sys.id === treeData.item?.sys?.contentType?.sys?.id
  )?.displayField;

  return (
    <li>
      <ListCard
        title={contentTypeName}
        subtitle={treeData.item?.fields?.[displayField]?.[defaultLocale]}
        selectedOption={treeData.item?.metadata?.tags[0]?.sys?.id}
        options={governanceTags}
        entryId={treeData.item?.sys?.id}
        sdk={sdk}
      />
      <ul>
        {treeData.children?.map((childTreeData) => (
          <ListItem
            treeData={childTreeData}
            governanceTags={governanceTags}
            contentTypes={contentTypes}
            sdk={sdk}
          />
        ))}
      </ul>
    </li>
  )
}

const List = ({ treeData, governanceTags, contentTypes, sdk }: any) => {
  return (
    <ul>
      <ListItem
        treeData={treeData}
        governanceTags={governanceTags}
        contentTypes={contentTypes}
        sdk={sdk}
      />
    </ul>
  );
};

export default List;
