import React, { useEffect, useState } from 'react';
import { Spinner, Stack } from '@contentful/f36-components';
import { DialogAppSDK } from '@contentful/app-sdk';
import { useSDK, useAutoResizer } from '@contentful/react-apps-toolkit';
import { getContentTypes, getReferences } from '../utils/contentful';
import { createTree } from '../utils/tree';
import List from '../components/List';
import './Dialog.css';

const Dialog = () => {
  const sdk = useSDK<DialogAppSDK>();
  const cma = sdk.cma;
  const { entryId } = sdk.parameters.invocation as any;
  const { pageComponentContentTypes, governanceTags } =
    sdk.parameters.installation.applicationConfig;
  const [treeData, setTreeData] = useState<any | null>(null);
  const [contentTypes, setContentTypes] = useState<any>(null);

  useAutoResizer();

  useEffect(() => {
    (async () => {
      const contentTypes = await getContentTypes(cma);

      if (contentTypes) {
        setContentTypes(contentTypes.items || []);
      }

      const references = await getReferences(cma, entryId, 1);

      if (references) {
        const tree = await createTree(cma, references, pageComponentContentTypes);

        if (tree) {
          setTreeData(tree);
        }
      }
    })();
  }, [cma, entryId, pageComponentContentTypes]);

  return (
    <>
      {treeData?.item === undefined ? (
        <Stack flexDirection="column" padding="spacing4Xl">
          <Spinner customSize={50} />
        </Stack>
      ) : (
        <List
          treeData={treeData}
          governanceTags={governanceTags}
          contentTypes={contentTypes}
          sdk={sdk}
        />
      )}
    </>
  );
};

export default Dialog;
