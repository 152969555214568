import { EntryProps } from 'contentful-management';

export async function getEntry(cma: any, entryId: any): Promise<EntryProps | null> {
  try {
    return await cma.entry.get({ entryId });
  } catch {
    return null;
  }
}

export async function getReferences(
  cma: any,
  entryId: any,
  include: number = 1
): Promise<any | null> {
  try {
    return await cma.entry.references({ entryId, include });
  } catch {
    return null;
  }
}

export async function getContentTypes(cma: any): Promise<any | null> {
  try {
    return await cma.contentType.getMany({});
  } catch {
    return null;
  }
}

export async function updateEntry(
  cma: any,
  entryId: string,
  selectedOption: string,
  governanceTags: any
): Promise<any | null> {
  const entryData = await getEntry(cma, entryId);
  const existingTags = entryData?.metadata?.tags;
  const adjustedTags: any = [];

  existingTags?.forEach((tag) => {
    if (!governanceTags.includes(tag.sys.id)) {
      adjustedTags.push(tag);
    }
  });

  if (entryData?.metadata) {
    entryData.metadata.tags = adjustedTags;
  }

  if (selectedOption) {
    entryData?.metadata?.tags.push({
      sys: {
        type: 'Link',
        linkType: 'Tag',
        id: selectedOption,
      },
    });
  }

  await cma.entry.update({ entryId }, entryData);
}
