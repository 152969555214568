import { getReferences } from '../utils/contentful';

async function createSubTree(data: any, id: any, allowedTypes: any): Promise<any | null> {
  const entry = data.includes.Entry.find((entry) => entry.sys.id === id);

  const subTree = {
    item: entry,
    children: [],
  };

  if (entry.fields) {
    for (const fields of Object.values(entry.fields)) {
      for (const localizedFields of Object.values(fields)) {
        if (localizedFields.sys) {
          const currentEntry = data.includes.Entry.find((entry) => entry.sys.id === localizedFields.sys.id);

          if (currentEntry && allowedTypes.includes(currentEntry.sys.contentType.sys.id)) {
            const entryChildren = await createSubTree(data, localizedFields.sys.id, allowedTypes);

            if (entryChildren) {
              subTree.children.push(entryChildren);
            }
          }
        }

        if (Array.isArray(localizedFields)) {
          for (const localizedField of localizedFields) {
            if (localizedField.sys) {
              const currentEntry = data.includes.Entry.find((entry) => entry.sys.id === localizedField.sys.id);

              if (currentEntry && allowedTypes.includes(currentEntry.sys.contentType.sys.id)) {
                const entryChildren = await createSubTree(data, localizedField.sys.id, allowedTypes);

                if (entryChildren) {
                  subTree.children.push(entryChildren);
                }
              }
            }
          }
        }
      }
    }
  }

  return subTree;
}

export async function createTree(cma: any, data: any, allowedTypes: any): Promise<any | null> {
  if (!data.items) {
    return null;
  }

  const tree = {
    item: data.items[0],
    children: [],
  };

  for (const fields of Object.values(data.items[0].fields)) {
    for (const localizedFields of Object.values(fields)) {
      if (localizedFields.sys) {
        const entry = data.includes.Entry.find((entry) => entry.sys.id === localizedFields.sys.id);

        if (entry && allowedTypes.includes(entry.sys.contentType.sys.id)) {
          const references = await getReferences(cma, localizedFields.sys.id, 9);

          if (references?.includes?.Entry) {
            data.includes.Entry.push(...references.includes.Entry);
          }

          const entryChildren = await createSubTree(data, localizedFields.sys.id, allowedTypes);

          if (entryChildren) {
            tree.children.push(entryChildren);
          }
        }
      }

      if (Array.isArray(localizedFields)) {
        for (const localizedField of localizedFields) {
          const entry = data.includes.Entry.find((entry) => entry.sys.id === localizedField.sys.id);

          if (entry && allowedTypes.includes(entry.sys.contentType.sys.id)) {
            const references = await getReferences(cma, localizedField.sys.id, 9);

            if (references?.includes?.Entry) {
              data.includes.Entry.push(...references.includes.Entry);
            }

            const entryChildren = await createSubTree(data, localizedField.sys.id, allowedTypes);

            if (entryChildren) {
              tree.children.push(entryChildren);
            }
          }
        }
      }
    }
  }

  return tree;
}
