import React from 'react';
import { Stack, Button } from '@contentful/f36-components';
import { SidebarAppSDK } from '@contentful/app-sdk';
import { useSDK, useAutoResizer } from '@contentful/react-apps-toolkit';

const Sidebar = () => {
  const sdk = useSDK<SidebarAppSDK>();

  useAutoResizer();

  return (
    <Stack flexDirection="column" alignItems="flex-start">
      <Button
        onClick={() =>
          sdk.dialogs.openCurrentApp({
            title: 'Manage Content Ownership',
            shouldCloseOnOverlayClick: true,
            shouldCloseOnEscapePress: true,
            width: 'fullWidth',
            parameters: {
              entryId: sdk.ids.entry,
            },
          })
        }
      >
        Manage Content Ownership
      </Button>
    </Stack>
  );
};

export default Sidebar;
